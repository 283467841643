#container {
	background-image: radial-gradient(#2f8e2c,#2e7423);
	background-color: #2e7423;
	text-align: center;
}
header {
	max-width: 900px;
	text-align: left;
	margin: 0 auto;
	padding: 20px 16px;
}
nav {
	background: #222;
}
nav ul {
	width: 900px;
	text-align: left;
	margin: 0 auto;
	padding: 0;
	list-style: none;
}
nav ul li {
	margin: 0;
	padding: 12px 0 12px 4px;
}
nav ul li a {
	color: #fff;
	text-decoration: none;
}
#logo {
	height: 33px;
	background: url(images/unibet-logo.png) no-repeat;
	background-size: 200px 33px;
	text-indent: -999em;
}
#content {
	max-width: 900px;
	text-align: left;
	padding: 0 16px;
	margin: 0 auto 16px auto;
}
article {
	padding: 8px 16px 16px;
	background: #fff;
	border-radius: 4px;
	overflow: hidden;
}
article h1 {
	font-size: 22px;
}
article .preamble {
	font-weight: bold;
}

#live-matches{
	float: left;
	width: 100%;
	height: 200px;
	margin-bottom: 15px;
}

article aside{
	float: right;
}
article aside h2 {
	font-size: 15px;
	margin: 4px 0;
}
article aside img {
	width: 220px;
}
footer {
	background: #333;
	color: #999;
}
footer .inner {
	max-width: 900px;
	text-align: left;
	margin: 0 auto;
	padding: 16px;
}
footer .inner p {
	margin: 0;
}

@media(min-width: 767px){
	#live-matches{
		height: 170px;
		width: calc(100% - 236px);
	}
	article aside{
		width: 220px;
		padding: 0 0 8px 16px;
		border-left: solid 1px #ccc;
	}
}
