.ticker-item{
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 100%;
  transform: translateY(-50%) !important;
  padding: 0 15px;
}

.score{
  color: #FEC800;
  font-size: 24px;
  margin-bottom: 15px;
}

.teams{
  color: white;
  font-size: 16px;
  vertical-align: top;
  margin-bottom: 15px;
}
.teams .icon{
  vertical-align: middle;
  margin-right: 5px;
}

.start-time{
  color: #a5a5a5;
  margin-bottom: 15px;
}

button{
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  height: 30px;
  background-color: #3aaa35;
  color: white;
  font-weight: bold;
  cursor: pointer;
}