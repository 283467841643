
* {
	box-sizing: border-box;
}
body {
  background: #333;
	font-family: 'RobotoRegular','Roboto','Droid Sans','Helvetica Neue','Segoe UI','Segoe','Open Sans','Helvetica','Arial',sans serif;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	padding: 0;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}